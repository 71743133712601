import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import "./_team.scss";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TeamSlider from "../Slider/TeamSlider";
import Spacing from "../Spacing";

import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

export default function AboutPage() {
  pageTitle("About");

  const funfaceData = [
    {
      title: "Global Happy Clients",
      factNumber: "40K",
    },
    {
      title: "Project Completed",
      factNumber: "50K",
    },
    {
      title: "Team Members",
      factNumber: "245",
    },
    {
      title: "Digital products",
      factNumber: "550",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}

      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title={`Transforming Advertising - Spreading Prosperity`}
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="_m0">
                We are dedicated to sharing profits with advertisers and
                publishers, minimizing costs for advertisers by providing the
                best marketing and advertising solutions to promote their brands
                and maximize performance. At the same time, publishers can
                benefit from optimizing traffic and delivering optimal payouts
                to conversion offers to generate high revenue.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="_separator _accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.png"
              alt="About"
              className="w-100 _radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          {/* <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 _radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div> */}
          {/* <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 _radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div> */}
        </Div>
      </Div>
      {/* <Spacing lg="75" md="55" /> */}
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      {/* <Div className="container">
        <FunFact
          title="Our fun fact"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div> */}
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      {/* <Spacing lg="100" md="80" /> */}
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="_image_layer _style1">
              <Div className="_image_layer_in">
                <img
                  src="/images/about_img_2.jpg"
                  alt="About"
                  className="w-100 _radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading title="Foundation built on trust" subtitle="">
              <Spacing lg="30" md="20" />
              <p className="_m0">
                We understand that our success as a company is ultimately linked
                to the success of our clients. Therefore, we are committed to
                providing each client, regardless of size, the resources, and
                expertise needed to thrive in the online marketplace. We believe
                in long term association and shared prosperity.
              </p>
              {/* <Spacing lg="15" md="15" />
              <p className="_m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining.
              </p> */}
              <Spacing lg="30" md="30" />
              <Div className="_separator _accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}
      <Spacing lg="150" md="80" />
      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" /> */}
      <Div className="container">
        <SectionHeading
          title="Our team members"
          subtitle=""
          variant="_style1"
        />
        {/* <Spacing lg="85" md="45" /> */}
        {/* <TeamSlider /> */}
      </Div>
      {/* End Team Section */}
      
      {/* ================= TeamAreaGroup Start =================*/}
      <div className="team-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="/images/vinay.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="https://www.linkedin.com/in/vinaykrishnaojha/">
                        <Icon icon="mdi:linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:vinay@thesocialeye.co.in">
                        <Icon icon="ic:baseline-email" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <p>Vinay Krishna Ojha</p>
                  </h5>
                  <p>Operations & Sales Lead</p>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-4">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="/images/prashant.jpg" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="https://www.linkedin.com/in/prashantstiwari/">
                        <Icon icon="mdi:linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:prashant@thesocialeye.co.in">
                        <Icon icon="ic:baseline-email" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <p>Prashant Shekhar Tiwari</p>
                  </h5>
                  <p>CFO & Delivery Lead</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="/images/niraj.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="https://www.linkedin.com/in/niraj-y-a1a0a4307/">
                        <Icon icon="mdi:linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:niraj@thesocialeye.co.in">
                        <Icon icon="ic:baseline-email" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <p>Niraj Y</p>
                  </h5>
                  <p>Client Operation Lead</p>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4 col-md-4">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="/images/aditya.jpg" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="https://www.linkedin.com/in/devxaditya/">
                        <Icon icon="mdi:linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Icon icon="ic:baseline-email" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <p>Aditya Singh</p>
                  </h5>
                  <p>Media Strategist</p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="/images/kajal.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="https://www.linkedin.com/in/kajal-parsolaya/">
                        <Icon icon="mdi:linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:kajal@thoughtcanopy.com">
                        <Icon icon="ic:baseline-email" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <p>Kajal K.</p>
                  </h5>
                  <p>Branding and Performance Executive</p>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-4">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="/images/joey.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <p>Joey</p>
                  </h5>
                  <p>Chief Happiness Officer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================= TeamAreaGroup End =================*/}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s transform our busineses together!"
          btnText="Connect with us!"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
