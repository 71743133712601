import React from "react";
import { Link, NavLink } from "react-router-dom";
import Div from "../Div";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import Privacy from "../Pages/Privacy";
import "./footer.scss";

export default function Footer() {
  // const copyrightLinks = [
  //   {
  //     title: "Privacy Policy",
  //     href: "/privacy",
  //   },
  // ];

  return (
    <footer className="footer">
      <Div className="container">
        <Div className="bottom_footer">
          <Div className="copyright">
            © 2020-25 <Link to="/"> The Social Eye.</Link> All rights reserved.
          </Div>

          <SocialWidget />
          <Div className="bottom_footer_right">
            {/* <Privacy/> */}
            <NavLink
                        to="/privacy"
                        // onClick={() => setMobileToggle(false)}
                        
                      >
                        Privacy Policy
                      </NavLink>
            {/* <MenuWidget menuItems={copyrightLinks} variant=" _style2" /> */}
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
